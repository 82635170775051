import enGB from 'date-fns/locale/en-GB';
import { DefaultConfig } from './types';
import { defaultConfig } from './default';

export const defaultCompound: DefaultConfig = {
  ...defaultConfig,
  brand: 'compound-uk',
  currency: 'GBP',
  currencyLocales: 'en-EN',
  country: 'United Kingdom',
  healthCoachCreation: true,
  defaultTimezone: 'Europe/London',
  dateFnsLocale: enGB,
  lgaEnabled: true,
  // disabled temporarily, more info: https://linear.app/eucalyptus/issue/ENG-1777/[admins-ui]-temp-disable-shop-plugs
  plugsEnabled: false,
  shopEnabled: true,
  isDigitalSigningEnabled: true,
  isDispensementReuseEnabled: true,
};

import { defaultCompound } from './default-compound-uk';
import { sharedProdEuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultCompound,
  ...sharedProdEuEnv,
  environment: 'production',
  apiUrl: 'https://api.admin.eu.eucalyptus.vc',
  restApiUrl: 'https://api.admin.uk.compound.co',
  assetsUrl:
    'https://storage.googleapis.com/euc-services-prod-compound-uk-assets',
  logoUrl:
    'https://storage.googleapis.com/assets-compound-uk/compound-logo.svg',
  shopifyHostname: 'compound-uk.myshopify.com',
  userAppUrl: 'https://app.uk.compound.co',
  doctorsAppUrl: 'https://prescribers.uk.compound.co',
  googleOAuthClientId:
    '966685249037-gin9l5mhlahpk6eud426k1fu32ksnvm5.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.uk.compound.co',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee5',
};

import { gql, useMutation } from '@apollo/client';
import { Button } from 'components/button';
import { Drawer } from 'components/drawer';
import { Dropdown, Option } from 'components/dropdown';
import { Input } from 'components/input';
import { Label } from 'components/label';
import { useHasPermissions } from 'components/permissions';
import { TextArea } from 'components/text-area';
import {
  Maybe,
  MedicalProfile,
  PregnancyStatus,
  Sex,
  UpsertMedicalRecordMutation,
  UpsertMedicalRecordMutationVariables,
} from 'graphql/types';
import { useNotifications } from 'notifications';
import { useForm } from 'react-hook-form';

const sexOptions: Option[] = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Intersex',
    value: 'INTERSEX',
  },
  {
    label: 'Unknown',
    value: 'UNKNOWN',
  },
];

const pregnancyStatusOptions: Option[] = [
  {
    label: 'Pregnant',
    value: 'PREGNANT',
  },
  {
    label: 'Not pregnant',
    value: 'NOT_PREGNANT',
  },
  {
    label: 'Potentially pregnant',
    value: 'POTENTIALLY_PREGNANT',
  },
  {
    label: 'Trying for a baby',
    value: 'TRYING_FOR_BABY',
  },
  {
    label: 'Breastfeeding',
    value: 'BREASTFEEDING',
  },
  {
    label: 'N/A',
    value: 'NOT_APPLICABLE',
  },
];

export type EditMedicalRecordDrawerProps = {
  customerId: string;
  medicalProfile: MedicalProfile;
  show: boolean;
  onClose: () => void;
};

export const EditMedicalRecordDrawer: React.FC<
  EditMedicalRecordDrawerProps
> = ({ show, onClose, customerId, medicalProfile }) => {
  const showNotification = useNotifications();

  const canEditAllergies = useHasPermissions(['EDIT_MEDICAL_RECORD_ALLERGIES']);
  const canEditCurrentMedications = useHasPermissions([
    'EDIT_MEDICAL_RECORD_CURRENT_MEDICATIONS',
  ]);
  const canEditDob = useHasPermissions(['EDIT_MEDICAL_RECORD_DOB']);
  const canEditHeight = useHasPermissions(['EDIT_MEDICAL_RECORD_HEIGHT']);
  const canEditMedicalConditions = useHasPermissions([
    'EDIT_MEDICAL_RECORD_MEDICAL_CONDITIONS',
  ]);
  const canEditPregnancyStatus = useHasPermissions([
    'EDIT_MEDICAL_RECORD_PREGNANCY_STATUS',
  ]);
  const canEditSex = useHasPermissions(['EDIT_MEDICAL_RECORD_SEX']);

  // FIXME: Weight (only field) requires something called a provenance type that is baked into
  // the RPC/svc. For doctors, this is set as the consultationId, but changes by admins are not
  // related to a specific consult. I don't have time to unpick all of that, so I'm electing not
  // to allow admins to alter weight.
  // const canEditWeight = useHasPermissions(['EDIT_MEDICAL_RECORD_WEIGHT']);

  const {
    handleSubmit,
    control,
    register,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<{
    allergyIntolerances?: string | null;
    conditions?: string | null;
    height?: number | null;
    medicationUsages?: string | null;
    sex?: Maybe<Sex>;
    dob: Maybe<string>;
    pregnancyStatus?: Maybe<PregnancyStatus>;
    weightKg?: number | null;
    weightAction: 'CREATE' | 'OVERRIDE';
  }>({
    defaultValues: {
      allergyIntolerances: medicalProfile.allergies,
      conditions: medicalProfile.existingConditions,
      height: medicalProfile.height,
      medicationUsages: medicalProfile.currentMedications,
      sex: medicalProfile.sex,
      dob: medicalProfile.dob,
      pregnancyStatus: medicalProfile.pregnancyStatus,
    },
  });

  const [upsertMedicalRecord, { loading }] = useMutation<
    UpsertMedicalRecordMutation,
    UpsertMedicalRecordMutationVariables
  >(
    gql`
      mutation UpsertMedicalRecord($input: UpsertMedicalRecordInput!) {
        upsertMedicalRecord(input: $input) {
          medicalProfile {
            id
            dob
            existingConditions
            allergies
            currentMedications
            height
            weight
            sex
            age
            bmi
            diabetesRiskLevel
            pregnancyStatus
            requiresClinicalReview
          }
          medicalRecordHistory {
            id
            field
            currentValue
            previousValue
            createdAt
            updatedAt
            updatedBy {
              id
              role
              roles {
                id
                value
              }
              fullName
              clinicianName
            }
          }
        }
      }
    `,
    {
      onCompleted: () => {
        showNotification({
          type: 'success',
          message: 'Medical record edited',
        });
      },
      onError: () => {
        showNotification({
          type: 'error',
          message: 'Error editing medical record',
        });
      },
      update: (cache, { data }) => {
        const history = data?.upsertMedicalRecord?.medicalRecordHistory;
        if (!history) {
          return;
        }

        // there is no customer id on ANYTHING that is returned from MedicalRecord, so this will do.
        const cacheKey = `User:${customerId}`;

        cache.modify({
          id: cacheKey,
          fields: {
            medicalRecordHistory: () => history,
          },
        });
      },
    },
  );

  const onSubmit = handleSubmit(
    async ({
      allergyIntolerances,
      conditions,
      medicationUsages,
      dob,
      height,
      pregnancyStatus,
      sex,
    }) => {
      await upsertMedicalRecord({
        variables: {
          input: {
            customerId,
            ...(canEditAllergies ? { allergyIntolerances } : {}),
            ...(canEditCurrentMedications ? { medicationUsages } : {}),
            ...(canEditDob ? { dob } : {}),
            ...(canEditHeight && height ? { heightCm: Number(height) } : {}),
            ...(canEditMedicalConditions ? { conditions } : {}),
            ...(canEditPregnancyStatus ? { pregnancyStatus } : {}),
            ...(canEditSex ? { sex } : {}),
          },
        },
      });

      handleOnClose();
    },
  );

  const handleOnClose = (): void => {
    onClose();
    reset();
  };

  return (
    <Drawer show={show} onClose={handleOnClose}>
      <div className="h-full overflow-scroll">
        <div className="flex flex-col justify-between top-0 bg-gray-200 p-6">
          <h1 className="text-2xl font-semibold text-gray-900 mb-2">
            Edit Patient Record
          </h1>
          <section className="flex flex-col bg-gray-200">
            <div className="mb-6">
              <p>
                These fields are part of the patient record and used in the
                script. Any changes will be recorded in the patient details
                history.
              </p>
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Personal Information
            </h2>
            <form
              onSubmit={onSubmit}
              className="grid grid-cols-2 gap-y-3 gap-x-2"
            >
              {canEditDob && (
                <div>
                  <div className="mb-3">
                    <Label htmlFor="dob">DOB</Label>
                  </div>
                  <div className="w-full">
                    <input
                      id="dob"
                      name="dob"
                      type="date"
                      ref={register}
                      className="hover:border-gray-500 rounded p-3 w-full"
                    />
                  </div>
                </div>
              )}
              {canEditSex && (
                <div>
                  <div className="w-full">
                    <Dropdown
                      name="sex"
                      label="Sex"
                      control={control}
                      options={sexOptions}
                    />
                  </div>
                </div>
              )}
              {canEditHeight && (
                <div className="col-span-2">
                  <div className="mb-3">
                    <Label htmlFor="height">Height (cm)</Label>
                  </div>
                  <div className="">
                    <div className="w-full">
                      <Input
                        name="height"
                        type="number"
                        step={0.1}
                        min={90}
                        ref={register}
                      />
                    </div>
                  </div>
                </div>
              )}
              {canEditPregnancyStatus && (
                <div className="col-span-2">
                  <div className="w-full">
                    <Dropdown
                      name="pregnancyStatus"
                      label="Pregnancy Status"
                      control={control}
                      options={pregnancyStatusOptions}
                    />
                  </div>
                </div>
              )}
              {canEditAllergies && (
                <div className="col-span-2">
                  <TextArea
                    label="Allergies"
                    name="allergyIntolerances"
                    placeholder="None listed"
                    defaultValue={watch('allergyIntolerances') ?? undefined}
                    maxLength={500}
                    ref={register}
                    rows={4}
                    resize
                  />
                </div>
              )}
              {canEditMedicalConditions && (
                <div className="col-span-2">
                  <TextArea
                    label="Medical conditions"
                    name="conditions"
                    placeholder={'None listed'}
                    defaultValue={watch('conditions') ?? undefined}
                    ref={register}
                    maxLength={500}
                    rows={4}
                    resize
                  />
                </div>
              )}
              {canEditCurrentMedications && (
                <div className="col-span-2">
                  <TextArea
                    label="Current medications"
                    name="medicationUsages"
                    placeholder="None listed"
                    defaultValue={watch('medicationUsages') ?? undefined}
                    ref={register}
                    maxLength={500}
                    rows={4}
                    resize
                  />
                </div>
              )}
              <div className="flex justify-between col-span-2 space-x-4">
                <Button
                  variant="outline"
                  color="danger"
                  onClick={handleOnClose}
                  fullWidth
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={loading}
                  disabled={!isDirty}
                  fullWidth
                >
                  Save
                </Button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </Drawer>
  );
};
